import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";
import { StaticImage } from "gatsby-plugin-image"

const Business = () => (
  <Layout>
    <Meta
      title="事業内容"
      desc="木原興業株式会社は技術力を駆使して多種多様な電気設備を組み上げ、社会に不可欠なシステム作りを行っています。"
    />{ }
    <Heading.H1 text="事業内容" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <div>
        <Heading.H3 text="電機事業" />
        <p>木原興業株式会社は、時代のニーズにあったシステムや製品を日々、提案し続けています。</p>
        <div className="px-4 my-4 flex flex-col space-y-2">
          <Link to="/products" className="flex items-center">
            <Chevron className="w-3 h-3 text-primary" />
            <span className="text-primary">取扱い商品</span>
          </Link>
          <Link to="/branch" className="flex items-center">
            <Chevron className="w-3 h-3 text-primary" />
            <span className="text-primary">事業所一覧</span>
          </Link>
        </div>
      </div>
      <div className="lg:flex lg:flex-row">
        <StaticImage src="../images/office_1-4.jpg" className="w-full mb-8 lg:mt-5 lg:w-96 lg:flex-shrink-0" />
        <p className="mb-4 lg:block lg:py-5 lg:pl-10">
        当社は、技術力を駆使して多種多様な電気設備を組み上げ、
        社会に不可欠なシステム作りを行っています。
        目まぐるしい技術革新や産業社会の進展に伴い、
        これら設備にはより高い信頼性が求められており、
        これからもより一層社会に役立つものを、
        提案、実現させていきたいと願っています。
        </p>
      </div>
      <div className="lg:flex lg:flex-row-reverse">
        <StaticImage src="../images/biz_2.jpg" className="w-full mb-8 lg:mt-5 lg:w-96 lg:flex-shrink-0" />
        <p className="lg:block lg:py-5 lg:pr-10">
        当社はビルや工場などの受配電・変電、計測制御機器などに関するインフラ整備を手掛ける、電気・電子機器の総合商社です。
        一般の方が、私たちの扱う製品を目にしたり触れたりすることはほとんどないと思いますが、
        私たちは、様々な業種の工場をはじめ病院や大学などのビル・建物における電気設備を組み上げ、
        こういった施設・設備が誤作動することなくコンスタントに稼働するよう最適なシステムを作り上げる、
        いわば”縁の下の力持ち”。
        産業や社会のあらゆる分野にかかわる様々な電気・機械設備関連のニーズに応え、
        幅広く膨大な機器の中から最適なものを選びシステム化、販売するのはもちろん、
        既存設備のメンテナンスや改良・更新の提案、
        さらにはお客様の抱える問題点をいち早く把握し解決に導くトータルソリューションカンパニーをめざしています。
        </p>
      </div>
      <Heading.H3 text="システム事業" />
      <div className="lg:flex lg:flex-row">
        <StaticImage src="../images/biz_3.jpg" className="w-full mb-8 lg:mt-5 lg:w-96 lg:flex-shrink-0" />
        <p className="mb-4 lg:block lg:py-5 lg:pl-10">
        IT戦略の策定から実行まで、専門家が丁寧にサポートします。お客様のビジネス目標達成のために最適なITソリューションを提案し、競争力を強化します。
        安全でスケーラブルなクラウド環境を構築し、データ管理やビジネスの拡張をサポートします。AWS、Azure、MS365など、主要なクラウドプラットフォームに対応しています。
        業務の効率化と生産性向上を実現するため多種多様なシステムコーディネイトを提供しています。要件定義から設計、開発、運用まで、一貫したサポートを提供します。
        お客様の多様なニーズに対応するため、柔軟なサービス提供を心がけています。小規模なプロジェクトから大規模なシステム統合まで、幅広く対応可能です。
        常に最新の技術動向を追い、革新的なソリューションを提供します。AI、IoT、ビッグデータなど、次世代の技術を駆使してお客様のビジネスに価値を提供します。</p>
      </div>
    </div>
  </Layout>
)

export default Business;